/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { Blacklist } from "interfaces";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FilterProcessor } from "utils/filter-processor";
import { BlacklistService } from "services";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { useNumberFormat } from "hooks";
import { toTitleCase } from "utils/titlecase";
import dayjs from 'dayjs';

const initialValues = {
    search: "",
    start_date: "",
    end_date: "",
    product: ""
};

const filterOptions = {
    search: {
        canShow: true,
        label: ""
    },
    select: {
        canShow: false,
        label: "Status",
        options: []
    },
    dates: {
        canShow: false,
    },
};

const BlacklistPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [recordCount, setRecordCount] = useState(100);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState<any>(initialValues);

    const [blacklistData, setBlacklistData] = useState<Blacklist[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        const params = FilterProcessor(filters);
        params.page = page+1;
        params.per_page = rowsPerPage;

        BlacklistService.GetBlacklist(params)
            .then((res: any) => {
                setBlacklistData(res.data.data);
                setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });
    }, [filters, page, rowsPerPage]);

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setPage(0);
        setFilters({
            ...filters,
            [key]: value
        });
    }

    return (
        <div className="">
            <Header title="Blacklisted Merchants" />
            <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />

            {
                (isLoading) ? 
                <Loader /> :
                <>
                    {
                        (recordCount === 0) ? 
                        <EmptyPage /> :
                        <section className="mt-4">
                            <div className="d-flex justify-content-bewteen">
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Merchant Name</TableCell>
                                            <TableCell>Comapany Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Loan Type</TableCell>
                                            <TableCell>Loan Limit</TableCell>
                                            <TableCell>Interest Rate</TableCell>
                                            <TableCell>Date Blacklisted</TableCell>
                                            <TableCell>Comment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    { blacklistData.map((row, i) => (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{ toTitleCase(row.merchantName) }</TableCell>
                                            <TableCell>{ toTitleCase(row.companyName) }</TableCell>
                                            <TableCell>{ row.phoneNumber }</TableCell>
                                            <TableCell>{ row.loanName }</TableCell>
                                            <TableCell>{ useNumberFormat(row.loanLimit) }</TableCell>
                                            <TableCell>{ row.interestRate }%</TableCell>
                                            <TableCell>{ dayjs(row.dateBlacklisted).format('MMMM D, YYYY') }</TableCell>
                                            <TableCell>{ row.comment }</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ 10, 25, 100]}
                                                count={recordCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </section>
                    }
                </>

            }
        </div>
    )
}


export default BlacklistPage;