/* eslint-disable react-hooks/rules-of-hooks */

import { useState, useEffect } from "react";
import { Collection } from "interfaces";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FilterProcessor } from "utils/filter-processor";
import { CollectionService } from "services";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { useNumberFormat } from "hooks/useNumberFormat";
import { toTitleCase } from "utils/titlecase";
import dayjs from 'dayjs';

const initialValues = {
    search: "",
    start_date: "",
    end_date: "",
    product: ""
};

const filterOptions = {
    search: {
        canShow: true,
        label: ""
    },
    select: {
        canShow: true,
        label: "Status",
        options: [
            { id: 0, name: "Unprocessed" },
            { id: 1, name: "Matched" },
            { id: 2, name: "UnMatched" },
            { id: 3, name: "Reversed" },
            { id: 4, name: "Zanifu Acc Top Up" }
        ]
    },
    dates: {
        canShow: false,
    },
};

const C2BPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [recordCount, setRecordCount] = useState(100);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState<any>(initialValues);

    const [collections, setCollections] = useState<Collection[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        const params = FilterProcessor(filters);
        params['filter[select_filters]'] = params['filter[product]'];
        delete params['filter[product]'];
        params.page = page+1;
        params.per_page = rowsPerPage;

        CollectionService.GetCollections(params)
            .then((res: any) => {
                setCollections(res.data.data);
                setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });
    }, [filters, page, rowsPerPage]);

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setPage(0);
        setFilters({
            ...filters,
            [key]: value
        });
    }

    return (
        <div className="">
            <Header title="Collection Statements" />
            <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />
            
            {
                (isLoading) ? 
                <Loader /> :
                <>
                    {
                        (recordCount === 0) ? 
                        <EmptyPage /> :
                        <section className="mt-4">
                            <div className="d-flex justify-content-bewteen">
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Customer Name</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Receipt Number</TableCell>
                                            <TableCell>MSISDN</TableCell>
                                            <TableCell>Business Number</TableCell>
                                            <TableCell>Account Number</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Loan Type</TableCell>
                                            <TableCell>Date Applied</TableCell>
                                            <TableCell>Date Processed</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {collections.map((row, i) => (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{ toTitleCase(row.customerName) }</TableCell>
                                            <TableCell>{ toTitleCase(row.companyName) }</TableCell>
                                            <TableCell>{ row.receiptNumber }</TableCell>
                                            <TableCell>{ row.MSISDN }</TableCell>
                                            <TableCell>{ row.businessNumber }</TableCell>
                                            <TableCell>{ row.accountNumber }</TableCell>
                                            <TableCell>{ useNumberFormat(row.amount) }</TableCell>
                                            <TableCell>{ row.loanName }</TableCell>
                                            <TableCell>{ dayjs(row.dateApplied).format('lll') }</TableCell>
                                            <TableCell>{ dayjs(row.datePaid).format('lll') }</TableCell>
                                            <TableCell>{ row.status }</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ 10, 25, 100]}
                                                count={recordCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </section>
                    }
                </>

            }
        </div>
    )
}


export default C2BPage;