
const loader = () => {
  return (
    <div style={{ height:'20vh', position: 'relative'}}>
    <div className="loader-wrap">
      <div className="loader">
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
        <span className="loader-item"></span>
      </div>
    </div>
    </div>
  )
}

export default loader;