/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Header, Loader, EmptyPage } from "components";
import { useEffectOnce, useNumberFormat } from "hooks";

import { useState } from "react";
import { ApplicationService } from "services";

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dashboardSummary, setDashboardSummary] = useState<any>();
    const [porfolio, setPortfolio] = useState<any>();

    useEffectOnce(() => {
        ApplicationService.GetDashboardSummary()
        .then((res: any) => {
            setDashboardSummary(res.data);
        });

        ApplicationService.GetDashboardTable()
        .then((res: any) => {
            setPortfolio(res.data);
            setIsLoading(false);
        });
    });

    return (
        <div className="container">
            <Header title="Dashboard" />

            <h4 className="m-0 mt-3">System Summary</h4>
            <p className="hint"><strong>P + I</strong> = Principle + Interest</p>
            <div className="summary-wrapper my-4 py-3 px-4 d-flex justify-content-start flex-wrap">
                <div className="summary px-4">
                    <p className="title">P Disbursed</p>
                    <p className="value">{ (dashboardSummary?.amount_payable) ? useNumberFormat(dashboardSummary?.amount_disbursed) : "-" }</p>
                </div>
                <div className="summary px-4">
                    <p className="title">I Disbursed</p>
                    <p className="value">{ (dashboardSummary?.amount_payable) ? useNumberFormat(dashboardSummary?.amount_payable - dashboardSummary?.amount_disbursed) : "-" }</p>
                </div>
                <div className="summary px-4">
                    <p className="title">P+I Disbursed</p>
                    <p className="value">{ (dashboardSummary?.amount_payable) ? useNumberFormat(dashboardSummary?.amount_payable) : "-" }</p>
                </div>
                <div className="summary px-4">
                    <p className="title">P+I Collected</p>
                    <p className="value">{ (dashboardSummary?.amount_paid) ? useNumberFormat(dashboardSummary?.amount_paid) : "-" }</p>
                    {/* <p className="hint">Principle + Interest</p> */}
                </div>
                <div className="summary px-4">
                    <p className="title">P+I Current</p>
                    <p className="value">{ (dashboardSummary?.amount_within_tenure) ? useNumberFormat(dashboardSummary?.amount_within_tenure) : "-" }</p>
                    {/* <p className="hint">Principle + Interest</p> */}
                </div>
                <div className="summary px-4">
                    <p className="title">P+I Due</p>
                    <p className="value">{ (dashboardSummary?.amount_past_due) ? useNumberFormat(dashboardSummary?.amount_past_due) : "-" }</p>
                    {/* <p className="hint">Principle + Interest</p> */}
                </div>
                <div className="summary px-4">
                    <p className="title">Collection Rate</p>
                    <p className="value-large">{ (dashboardSummary?.amount_payable) ? Math.round((dashboardSummary?.amount_paid / dashboardSummary?.amount_payable) * 10000)/100 : "-" }%</p>
                </div>
                <div className="summary px-4">
                    <p className="title">Default Rate</p>
                    <p className="value-large">{ (dashboardSummary?.amount_payable) ? Math.round((dashboardSummary?.amount_past_due / dashboardSummary?.amount_payable) * 10000)/100 : "-" }%</p>
                </div>
            </div>

            <section className="mt-4">
                <div className="d-flex justify-content-bewteen">
                    <h4 className="section-header">Portfolio</h4>
                </div>
                {
                    (isLoading) ? 
                    <Loader /> :
                    <>
                        {
                            (porfolio.length === 0) ? 
                            <EmptyPage /> :
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Month</TableCell>
                                            <TableCell>P Disbursed</TableCell>
                                            <TableCell>I Disbursed</TableCell>
                                            <TableCell>P+I Disbursed</TableCell>
                                            <TableCell>P+I Collected</TableCell>
                                            <TableCell>P+I Current</TableCell>
                                            <TableCell>P+I Due</TableCell>
                                            <TableCell>Interest Earned Loss Rate</TableCell>
                                            <TableCell>Collection Rate</TableCell>
                                            <TableCell>Default Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        porfolio.map((row: any, i: number) => (
                                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className="text-capitalize">{ (new Date(row.date)).toLocaleString('default', { month: 'short', year: 'numeric' }) }</TableCell>
                                                <TableCell>{ useNumberFormat(row.amount_disbursed, 'decimal')}</TableCell>
                                                <TableCell>{ useNumberFormat((row.amount_payable - row.amount_disbursed), 'decimal')}</TableCell>
                                                <TableCell>{ useNumberFormat(row.amount_payable, 'decimal')}</TableCell>
                                                <TableCell>{ useNumberFormat(row.amount_paid, 'decimal')}</TableCell>
                                                <TableCell>{ useNumberFormat(row.amount_within_tenure, 'decimal')}</TableCell>
                                                <TableCell>{ useNumberFormat(row.amount_past_due, 'decimal')}</TableCell>
                                                <TableCell>{ (row.amount_past_due || row.amount_payable) ? `${Math.round((row.amount_past_due / (row.amount_payable-row.amount_disbursed)) * 10000)/100}%` : '-'}</TableCell>
                                                <TableCell>{ (row.amount_paid || row.amount_payable ) ? `${Math.round((row.amount_paid / row.amount_payable) * 10000)/100}%` : '-' }</TableCell>
                                                <TableCell>{ (row.amount_past_due || row.amount_payable) ? `${Math.round((row.amount_past_due / row.amount_payable) * 10000)/100}%` : '-' }</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </>
                }
            </section>
        </div>
    )
}


export default Dashboard;