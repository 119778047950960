import { useState } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import searchIcon from 'assets/icons/search.svg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SearchFilters = ({ filterOptions, onHandleSearchInput }: any) => {
    const [select, setProductName] = useState<string>("");
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs("2022-12-31T21:00:00.000Z"));
    const [endDate, setEndDate] = useState<Dayjs | null>(null);

    const handleChange = (event: SelectChangeEvent<typeof select>) => {
      const { target: { value }} = event;
      setProductName( value );

      onHandleSearchInput({ product: value });
    };

    const handleStartDateChange = (newValue: Dayjs | null) => {
        setStartDate(newValue);
        onHandleSearchInput({ start_date: dayjs(newValue).format('YYYY-MM-DD')});
    };
    const handleEndDateChange = (newValue: Dayjs | null) => {
        setEndDate(newValue);
        onHandleSearchInput({ end_date: dayjs(newValue).format('YYYY-MM-DD')});
    };

    const handleSearchInput = (value:string) => {
      onHandleSearchInput({ search: value });
    }

    return (
      <div className="mb-3">
        <hr />

        <p className="mt-2">Search Filters</p>

        <div className="d-flex justify-content-start align-items-center flex-wrap">
          {
            filterOptions?.search?.canShow &&
            <FormControl className="col-12 col-md-5 ps-0 mb-3 me-2" size="small">
              <InputLabel htmlFor="outlined-adornment-amount">{ filterOptions?.search?.label || "Search" }</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<img src={searchIcon} className="icon" alt="search icon" />}
                label={ filterOptions?.search?.label || "Search" }
                onChange={(event) => handleSearchInput(event.target.value)}
              />
            </FormControl>
          }
          {
            filterOptions?.dates?.canShow &&
            <>
              <div className="col-12 col-md-5 col-lg-2 ps-0 mb-3 me-2">
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
                      <DesktopDatePicker 
                        className="bg-white"
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        value={startDate}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField size="small" {...params} />} />
                  </LocalizationProvider>
                </FormControl>
              </div>
              <div className="col-12 col-md-5 col-lg-2 mb-3 me-2">
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
                        <DesktopDatePicker
                          className="bg-white"
                          label="End Date"
                          inputFormat="MM/DD/YYYY"
                          value={endDate}
                          onChange={handleEndDateChange}
                          renderInput={(params) => <TextField size="small" {...params} />} />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </>
          }
          {
            filterOptions?.select?.canShow &&
            <div className="col-12 col-md-5 col-lg-2 mb-3 me-2">
                <FormControl style={{ width: "100%" }} size="small">
                    <InputLabel id="select-label">{filterOptions.select.label}</InputLabel>
                    <Select labelId="select-label"
                        id="select-checkbox"
                        className="bg-white"
                        label={filterOptions.select.label}
                        value={select}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}>
                        {filterOptions.select.options.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                        </Select>
                </FormControl>
            </div>
          }
        </div>

        <hr />

      </div>
    )
}


export default SearchFilters;