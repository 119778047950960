/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { SearchFilters,  Header, Loader, EmptyPage } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffectOnce, useNumberFormat } from "hooks";
import { SalesService, SharedService, AuthorizationService } from "services";

const initialValues = {
    search: "",
    start_date: "",
    end_date: "",
    product: ""
};

const filterOptions:any = {
    search: {
        canShow: false,
        label: ""
    },
    select: {
        canShow: true,
        label: "VSM",
        options: []
    },
    dates: {
        canShow: false,
    },
};

const SalesDashboard = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<any>(initialValues);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const [sales, setSales] = useState<any[]>([]);
    const [summary, setsummary] = useState<any>();

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setFilters({
            ...filters,
            [key]: value
        });

        fetchData();
    }
    
    const fetchData = () =>{
        setIsLoading(true);

        const params = {
            bdo: filters.product
        };

        SalesService.GetSalesData(params)
            .then((res:any) => {
                setSales(res.data);
                setIsLoading(false);
            });
        SalesService.GetSalesSummaryData(params)
            .then((res:any) => {
                setsummary(res.data);
            });
    }

    const caclculateNewGrowthRate = (row: any) => {
        if (Number(row.new_revenue) === 0) return "0";

        if (row.previous_sales <= 0) return "100";

        return Math.round((row.new_revenue / Math.ceil(row.previous_sales * 0.3)) * 10000)/100;
    }

    const cacluateExpectedAmount = (row: any) => {
        if (row.previous_sales <= 0) return 0;

        return Number(row.previous_sales) + Math.ceil(row.previous_sales * 0.3);
    }

    const calculateGrowthRate = (row: any) => {
        if (Number(row.amount_payable) === 0) return 0;

        if (row.previous_sales <= 0) return 100;

        return Math.round((row.amount_payable/row.previous_sales) * 10000)/100;
    }

    const getMaxDays = (row: any) => {
        const currentDate = new Date(row?.date);
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        return (new Date(currentYear, currentMonth, 0)).getDate();
    }

    useEffect(() => {
        fetchData();
    }, [filters]);

    useEffectOnce(() => {
        const profile = AuthorizationService.GetUserProfile() ?? "{}";
        const decoded_profile = JSON.parse(profile);

        if (decoded_profile.role === 'TM') setShowFilters(true);

        fetchData();
        SharedService.GetBDOList()
            .then((res:any) => {
                const filter = [{
                    id: "",
                    name: "Select All",
                    phone_number: ""
                }, ...res.data];

                filterOptions.select.options = filter!;
            });
    });

    return (
        <div className="">
            <Header title="Order Sales" />
            {
                showFilters &&
                <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />
            }
            {
                (isLoading) ? 
                <Loader /> :
                <>
                    <div className="summary-wrapper my-4 py-3 px-4 d-flex justify-content-start flex-wrap">
                        <div className="summary px-4">
                            <p className="title">P+I Disbursed</p>
                            <p className="value">{ (summary?.disbursed_amount) ? useNumberFormat(summary?.disbursed_amount) : "0" }</p>
                            
                        </div>
                        <div className="summary px-4">
                            <p className="title">Disbursed This Month</p>
                            <p className="value">{ (summary?.current_disbursed_amount) ? useNumberFormat(summary?.current_disbursed_amount) : "0" }</p>
                            {/* <p className="hint">Principle + Interest</p> */}
                        </div>
                        <div className="summary px-4">
                            <p className="title">New Revenue This Month</p>
                            <p className="value">{ (summary?.current_new_revenue) ? useNumberFormat(summary?.current_new_revenue) : "0" }</p>
                            {/* <p className="hint">Principle + Interest</p> */}
                        </div>
                        <div className="summary px-4">
                            <p className="title">Average Growth Rate</p>
                            <p className="value-large">{ (summary?.average_rate) ? summary?.average_rate : "0" }%</p>
                        </div>
                    </div>

                    <section className="mt-4">
                        <div className="d-flex justify-content-bewteen">
                            <h4 className="section-header">Sales Per Month</h4>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell>Expected Amount</TableCell>
                                        <TableCell>Principle Disbursed</TableCell>
                                        <TableCell>Interest Disbursed</TableCell>
                                        <TableCell>Amount Disbursed</TableCell>
                                        <TableCell>Interest Earned</TableCell>
                                        <TableCell>P+I Due</TableCell>
                                        <TableCell>New Revenue</TableCell>
                                        <TableCell>Growth Rate</TableCell>
                                        <TableCell>New Revenue Rate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    sales.map((row: any, i: number) => (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell className="text-capitalize">{ (new Date(row.date)).toLocaleString('default', { month: 'short', year: 'numeric' }) }</TableCell>
                                            <TableCell>{ useNumberFormat(cacluateExpectedAmount(row), 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(row.application_amount, 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(row.amount_payable - row.application_amount, 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(row.amount_payable, 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat((Number(row.amount_payable) - Number(row.application_amount)), 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(row.amount_due, 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(row.new_revenue, 'decimal')}</TableCell>
                                            <TableCell>{ useNumberFormat(calculateGrowthRate(row), 'decimal') }</TableCell>
                                            <TableCell>{ `${caclculateNewGrowthRate(row)}%` }</TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </section>
                </>
            }
            
        </div>
    )
}

export default SalesDashboard;