/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import { Disbursment } from "interfaces";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNumberFormat } from "hooks";
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { FilterProcessor } from "utils/filter-processor";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { DisbuursmentService } from "services";

const initialValues = {
    search: "",
    start_date: "",
    end_date: "",
    product: ""
};
const filterOptions = {
    search: {
        canShow: true,
        label: ""
    },
    select: {
        canShow: false,
        label: "Status",
        options: [
            { id: 0, name: "Unprocessed" },
            { id: 1, name: "Success" },
            { id: 2, name: "Pending Acknowledgement" },
            { id: 3, name: "Failed" },
            { id: 4, name: "Invalid Access token" },
            { id: 5, name: "Pending Account Number" },
            { id: 6, name: "Reversed" }
        ]
    },
    dates: {
        canShow: false,
    },
};

const B2BPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [recordCount, setRecordCount] = useState(100);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState<any>(initialValues);

    const [disbursments, setDisbursment] = useState<Disbursment[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        const params = FilterProcessor(filters);
        params.page = page+1;
        params.per_page = rowsPerPage;

        DisbuursmentService.GetDisbursments(params)
            .then((res: any) => {
                setDisbursment(res.data.data);
                setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });
    }, [filters, page, rowsPerPage]);

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setPage(0);
        setFilters({
            ...filters,
            [key]: value
        });
    }

    return (
        <div className="">
            <Header title="Disbursement Statements" />
        
            <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />
            {
                (isLoading) ? 
                <Loader /> :
                <>
                    {
                        (recordCount === 0) ? 
                        <EmptyPage /> :
                        <section className="mt-4">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Customer Name</TableCell>
                                            <TableCell>Receipt Number</TableCell>
                                            <TableCell>Till Number</TableCell>
                                            <TableCell>Till Name</TableCell>
                                            <TableCell>Account Number</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Date Processed</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {disbursments.map((row, i) => (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{row.customerName}</TableCell>
                                            <TableCell>{row.receiptNumber}</TableCell>
                                            <TableCell>{row.tillNumber}</TableCell>
                                            <TableCell>{row.tillName}</TableCell>
                                            <TableCell>{row.accountNumber}</TableCell>
                                            <TableCell>{useNumberFormat(row.amount)}</TableCell>
                                            <TableCell>{(row.dateProcessed) ? dayjs(row.dateProcessed).format('DD/MM/YY HH:mm') : null}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ 10, 25, 100]}
                                                count={recordCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </section>
                }
                </>
            }
        </div>
    )
}


export default B2BPage;