export const FilterProcessor = (filters: any) => {
    const keys = Object.keys(filters);
    if (!keys) return filters;

    const obj = {}
    
    keys.forEach((key: string) => {
        delete Object.assign(obj, {[`filter[${key}]`]: filters[key] })[key];
    });

    return obj;
}