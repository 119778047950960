import React from 'react';
import { useRoutes } from "react-router-dom";
import routes from "router";
import './App.scss';

function App() {
  const pages = useRoutes(routes);

  return (
    <>{pages}</>
  );
}

export default App;
