/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { MerchantRegistration } from "interfaces";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import { useNumberFormat } from "hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CustomerService } from "services";
import { toTitleCase } from "utils/titlecase";
import { FilterProcessor } from "utils/filter-processor";
import TablePagination from "@mui/material/TablePagination";
import { Collapse, TableFooter } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";

const initialValues = {
  search: "",
  start_date: "",
  end_date: "",
  product: "",
};
const filterOptions = {
  search: {
    canShow: true,
    label: "",
  },
  select: {
    canShow: true,
    label: "Status",
    options: [
      { id: 0, name: "Unprocessed" },
      { id: 1, name: "Matched" },
      { id: 2, name: "UnMatched" },
      { id: 3, name: "Reversed" },
      { id: 4, name: "Zanifu Acc Top Up" },
    ],
  },
  dates: {
    canShow: false,
  },
};

const ChurnRiskCustomersPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [recordCount, setRecordCount] = useState(100);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<any>(initialValues);

  const [customerData, setCustomerData] = useState<MerchantRegistration[]>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setIsLoading(true);
    const params = FilterProcessor(filters);
    params.page = page + 1;
    params.per_page = rowsPerPage;

    CustomerService.GetChurnRiskCustomers(params).then((res: any) => {
      setCustomerData(res.data.data);
      setRecordCount(res.data.meta.total);
      setIsLoading(false);
    });
  }, [filters, page, rowsPerPage]);

  const handleChange = (changes: any) => {
    const key = Object.keys(changes)[0];
    const value = Object.values(changes)[0];

    setPage(0);
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const Row = (props: { row: ReturnType<any> }) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [productsLoading, setProductsLoading] = useState(false);

    const [products, setProducts] = useState([]);

    useEffect(() => {
      if (!open) {
        return;
      }
      setProductsLoading(true);

      CustomerService.GetCustomerProducts({ id: row.id })
        .then((res) => {
          setProductsLoading(false);
          setProducts(res.data.data);
        })
        .catch((err) => {
          setProductsLoading(false);
          console.log(err);
        });
    }, [row.id, open]);
    return (
      <>
        <TableRow
          key={row.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {toTitleCase(row.merchantName)}
          </TableCell>
          <TableCell>{toTitleCase(row.companyName)}</TableCell>
          <TableCell>{row.companyAlias}</TableCell>
          <TableCell>{row.phoneNumber}</TableCell>
          <TableCell>{row.onboarderName}</TableCell>
          <TableCell>{row.accountManger}</TableCell>
          <TableCell>
            {" "}
            {row.latitude !== 0 && row.longitude !== 0 ? (
              <a
                className="primary-color"
                href={`https://www.google.com.sa/maps/search/${row.latitude},${row.longitude}`}
                target="_blank"
                rel="noreferrer"
              >
                {toTitleCase(row.location)}
              </a>
            ) : (
              <>{toTitleCase(row.location)}</>
            )}
          </TableCell>
          <TableCell>
            {dayjs(row.lastCleared).format("MMM D, YYYY h:mm A")}
          </TableCell>
          <TableCell>{row.days}</TableCell>
          <TableCell>{row.applications}</TableCell>
        </TableRow>
        <TableRow className="m-0 p-0">
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {productsLoading ? (
                <Loader />
              ) : (
                <>
                  {products.length < 1 ? (
                    <p>No products available</p>
                  ) : (
                    <>
                      {products.map((product: any, i: number) => (
                        <div
                          key={i}
                          className="col-12 col-md-5 col-lg-3 col product-wrapper py-3 px-4 my-3"
                        >
                          <div className="d-flex justify-content-between">
                            <p>Product Name:</p>
                            <p>{product?.loan_description}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Limit:</p>
                            <p>{useNumberFormat(product?.flow_type_limit)}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Interest:</p>
                            <p>{product?.interest_rate}%</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p>Last Update:</p>
                            <p>
                              {dayjs(product?.updated_at).format(
                                "MMM D, YYYY h:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <div className="">
      <Header title="Churn Risk Customers" />
      <SearchFilters
        filterOptions={filterOptions}
        onHandleSearchInput={handleChange}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {recordCount === 0 ? (
            <EmptyPage />
          ) : (
            <section className="mt-4">
              <div className="d-flex justify-content-bewteen"></div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Merchant Name</TableCell>
                      <TableCell>Comapany Name</TableCell>
                      <TableCell>Alias</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Onboarder</TableCell>
                      <TableCell>Account Manager</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Last Cleared Date</TableCell>
                      <TableCell>Days Since Loan</TableCell>
                      <TableCell>No. Of Applications</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerData.length > 0 &&
                      customerData.map((row) => <Row key={row.id} row={row} />)}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default ChurnRiskCustomersPage;
