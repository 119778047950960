import { useState } from "react";
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { AuthorizationService } from "services";
import dayjs from 'dayjs';
import { Loader } from "components";

interface LoginValues {
    emailAddress: string;
    password: string;
}

const SignInPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const initialValues: LoginValues = { emailAddress: '', password: '' };
    const navigate = useNavigate();

    const validationSchema = object().shape({
        emailAddress: string()
            .required('Email Address is required'),
        password: string()
            .required('Password is required'),
    });

    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues, validationSchema,
        onSubmit: async () => {
            setIsLoading(true);
            setErrorMessage("");
            AuthorizationService.SignInApplication({
                email: values.emailAddress,
                password: values.password
            }).then((res) => {
                setIsLoading(false);
                navigate('/otp?val='+res.data.val);
            }).catch((err) => {
                setIsLoading(false);
                if (err.response.status === 401) {
                    setErrorMessage("Invalid email address or password");
                    return;
                } 
                setErrorMessage("Something wrong happened. Please try again later");
            })
        }
    });

    return (
        <div className="col-12 col-md-8 col-xl-6 mx-auto">
            <form onSubmit={handleSubmit}>
                <h3 className="mb-3">Sign In to Zanifu TMs Platform to continue</h3>
                {
                    errorMessage &&
                    <p className="error-message">{ errorMessage }</p>
                }
                { 
                    (isLoading) ? 
                    <Loader /> :
                    <>
                        <div className="form-group mb-3">
                            <label>Email Address</label>
                            <input name="emailAddress" className={`form-input ${Boolean(touched.emailAddress && errors.emailAddress) ? "error-field": ""}`} 
                                onBlur={handleBlur} onChange={handleChange} value={values.emailAddress} type="text" placeholder="Enter email address / phone number" required />
                            {Boolean(touched.emailAddress && errors.emailAddress) &&
                                <p className='error mt-2'> { errors.emailAddress }</p>
                            }
                        </div>
                        <div className="form-group mb-3">
                            <label>Password</label>
                            <input name="password" className={`form-input ${Boolean(touched.password && errors.password) ? "error-field": ""}`} 
                                onBlur={handleBlur} onChange={handleChange} value={values.password} type="password" placeholder="Enter password" required />
                            {Boolean(touched.password && errors.password) &&
                                <p className='error mt-2'> { errors.password }</p>
                            }   
                        </div>
                        <button className="btn btn-primary btn-full mt-3" type='submit'>
                            Sign In
                        </button>
                    </>
                }
            </form>
        </div>
    )
}

export default SignInPage;