import React from "react";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import chevon from 'assets/icons/chevrons-left.svg';
import menu from 'assets/icons/menu.svg';
import { Outlet, NavLink } from "react-router-dom";
import zanifu from 'assets/images/zanifu.png';
import { AuthorizationService } from "services";
import logout from "../../assets/icons/log-out.svg";
import { useEffectOnce } from "hooks";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

const LargeLayourWrapper = ({NavItems}: {NavItems: any}) => {
  const [open, setOpen] = React.useState(true);
  const [user, setUser] = React.useState<any>({});
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function onLogoutUserClicked() {
    AuthorizationService.LogoutUser();
  }

  useEffectOnce(() => {
    const user = sessionStorage.getItem('user-profile');
    if(user) setUser(JSON.parse(user))
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} 
          ModalProps={{ keepMounted: true }}
          sx={{  '& .MuiDrawer-paper': { boxSizing: 'border-box' } }}>
        <DrawerHeader>
          { open ? 
            <>
              <img className='nav-logo mx-auto' src={zanifu} alt="Zanifu Logo" />
              <IconButton onClick={handleDrawerClose}>
                <img src={chevon} alt="Toggle collapse menu button" />
              </IconButton>
            </>
          : 
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start" >
            <img src={menu} alt="Toggle expand menu button" />
          </IconButton>
          }
        </DrawerHeader>
        {
          open &&
            <div className="user-wrapper px-5 py-3">
              <p className="mb-2">Welcome,</p>
              <p>{user?.first_name} {user.last_name}</p>
              <p>{(user?.role === 'TM') ? 'Terittory Manager' : 'VSM'}</p>
            </div>
        }
        <Divider />
        <List>
          {NavItems.map((item:any, i: number) => (
            <NavLink key={i} to={item.itemId} end className={({ isActive }) => isActive ? "activeClassName" : undefined }>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon
                    sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                    <img src={item.icon} alt={ item.title + " icon" } />
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>
        <NavLink className="nav-item mt-auto"onClick={onLogoutUserClicked} to="sign-in">
            <img src={logout} alt="Logout icon" />
            <span>Logout</span>
        </NavLink>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <DrawerHeader className="d-md-none" />
        <div className='container'>
          <Outlet />
        </div>
      </Box>
    </Box>
  );
};

export default LargeLayourWrapper;
