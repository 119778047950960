/* eslint-disable import/no-anonymous-default-export */
import { AxiosWrapper } from "utils/axios.helper";
import { HandleError } from "./errorHandler.service";

const GetApplications = (filters?: any) => {
    return AxiosWrapper({
        url: "/getApplications",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetMinimumRepayments = (filters?: any) => {
    return AxiosWrapper({
        url: "/getMinimumRepaymentPortfolio",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetOrders = (filters?: any) => {
    return AxiosWrapper({
        url: "/getOrders",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetPorfolioAges = (filters?: any) => {
    return AxiosWrapper({
        url: "/getPortfolioAging",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetDashboardSummary = () => {
    return AxiosWrapper({
        url: "/getDashboardSummary",
        method: "get",
        params: {}
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetDashboardTable = () => {
    return AxiosWrapper({
        url: "/getDashboardTableData",
        method: "get",
        params: {}
    }).catch((error) => {
        return HandleError(error);
    });
}
export {
    GetApplications,
    GetMinimumRepayments,
    GetOrders,
    GetPorfolioAges,
    GetDashboardSummary,
    GetDashboardTable
};