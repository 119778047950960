/* eslint-disable import/no-anonymous-default-export */
import { AxiosWrapper } from "utils/axios.helper";
import { HandleError } from "./errorHandler.service";

const GetVists = (filters?: any) => {
  return AxiosWrapper({
      url: "/visits",
      method: "get",
      params: filters
  }).catch((error) => {
      return HandleError(error);
  });
}

export { GetVists }