import { AxiosWrapper } from "utils/axios.helper";

const SignInApplication = (data: {email: string, password: string}) => {
    return AxiosWrapper({
        url: "/login",
        method: "post",
        data: data
    });
}

const VerifyOTP = (otp: string, id: string) => {
    return AxiosWrapper({
        url: `/verify-otp/${otp}`,
        method: "get",
        params: {id}
    });
}

const LogoutUser = () => {
    sessionStorage.clear();
}

const GetUserProfile = () => {
    return sessionStorage.getItem('user-profile');
}

export {
    LogoutUser,
    SignInApplication,
    VerifyOTP,
    GetUserProfile
};
