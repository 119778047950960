/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNumberFormat } from "hooks";
import dayjs from 'dayjs';
import { ApplicationService, CommentService } from "services";
import { Application, MinimumRepayment } from "interfaces";
import { FilterProcessor } from "utils/filter-processor";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useFormik } from 'formik';
import { object, string } from 'yup';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}



const initialValues = {
    search: "",
    start_date: "2023-01-01",
    end_date: "",
    product: "",
    dues_filter: ""
};

const filterOptions = {
    search: {
        canShow: true,
        label: ""
    },
    select: {
        canShow: true,
        label: "Filter Product",
        options: [
            { id: 2, name: "JAZA" },
            { id: 6, name: "JAZA PRO" },
            { id: 8, name: "Jaza Max " },
            { id: 10, name: "SolvIt" },
            { id: 11, name: "Distributor Advance" },
            { id: 12, name: "Jaza Plus" }
        ]
    },
    dates: {
        canShow: true,
    },
};

const PortfolioPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [recordCount, setRecordCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState<any>(initialValues);
    const [dues, setDues] = useState<string>('');

    const [applications, setApplications] = useState<Application[]>([]);
    const [minRepayments, setMinRepayments] = useState<MinimumRepayment[]>([]);
    const [porfolioAges, setPorfolioAges] = useState<any>();
    const [value, setValue] = useState(0);

    const validationSchema = object().shape({
        comment: string()
            .required('comment is required')
    });

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };
    

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Fetch applications
    useEffect(() => {
        setIsLoading(true);
        const params = FilterProcessor(filters);
        params.page = page+1;
        params.per_page = rowsPerPage;
        params["filter[dues_filter]"] = dues;

        ApplicationService.GetApplications(params)
            .then((res: any) => {
                setApplications(res.data.data);
                setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });

        ApplicationService.GetMinimumRepayments(params)
            .then((res: any) => {
                setMinRepayments(res.data.data);
                // setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });
    }, [filters, dues, page, rowsPerPage]);

    // Fetch Portfolio
    useEffect(() => {
        const params = FilterProcessor(filters);

        ApplicationService.GetPorfolioAges(params)
        .then((res: any) => {
            setPorfolioAges(res.data);
        });
    }, [filters]);

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setPage(0);
        setFilters({
            ...filters,
            [key]: value
        });
    }

    const getDays = (date: string|undefined, loanDays: number ) => {
        if(typeof(date) !== 'string') return null;
        const date1 = dayjs().startOf('date');
        const date2 = dayjs(date).startOf('date');
        const dueDate = date2.add(loanDays, 'day');

        const days = date1.diff(dueDate, 'days');
        return days < 0 ? null : days;
    }

    const handleCardFilterClick = (type: string)  => {
        if (type === dues) setDues("");
        else setDues(type);

        setPage(0);
    }

    const processDueDaysColor = (date: string|undefined, loanDays: number) => {
        const days = getDays(date,loanDays) ?? 0;

        switch (true) {
            case (days >= 1 && days <= 3):
                return "text-blue";
            case (days >= 4 && days <= 7):
                return "text-yellow";
            case (days >= 8 && days <= 14):
                return "text-orange";
            case (days >= 15):
                return "text-red";
            default: 
                return "null";
        }
    }

    function Row(props: { row: ReturnType<any> }) {
        const { row } = props;
        const [open, setOpen] = useState(false);
        const [commentLoading, setCommentLoading] = useState(false);
        const [state, updateState] = useState<any>();

        const [comments, setComments] = useState([]);

        useEffect(() => {
            if (!open) {
                return;
            }
            setCommentLoading(true);

            CommentService.GetComments({id: row.id})
                .then((res) => {
                    setCommentLoading(false);
                    setComments(res.data.data);
                }).catch((err) => {
                    setCommentLoading(false);
                    console.log(err);
                });
            
        }, [row.id, open, state]);

        const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
            initialValues:{ comment: '' }, 
            validationSchema,
            onSubmit: async () => {
                CommentService.CreateComment({
                    comment: values.comment,
                    recordId: row.id
                }).then((res) => {
                    updateState(!state);
                    setIsLoading(false);
                }).catch((err) => {
                    setIsLoading(false);
                    // setErrorMessage("Something wrong happened. Please try again later");
                })
            }
        });
    
        return (
            <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{row.companyName}</TableCell>
                    <TableCell>{row.merchantName}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{row.loanName}</TableCell>
                    <TableCell>{dayjs(row.dateApproved).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(row.dateApproved).add(row.loanDays, 'days').format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{useNumberFormat(row.amountApplied) }</TableCell>
                    <TableCell>{useNumberFormat(row.amountPayable) }</TableCell>
                    <TableCell>{useNumberFormat(row.amountPaid) }</TableCell>
                    <TableCell component="th">{useNumberFormat(row.amountPayable - row.amountPaid) }</TableCell>
                    <TableCell className={processDueDaysColor(row.dateApproved, row.loanDays)}>{ getDays(row.dateApproved, row.loanDays) }</TableCell>
                </TableRow>
                <TableRow className="m-0 p-0">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <form className="d-flex align-items flex-wrap m-0 my-2 p-0" onSubmit={handleSubmit}>
                                <div className="comments col-12 col-md-5">
                                    {
                                        commentLoading ? 
                                        <Loader /> :
                                        <>
                                            {
                                                comments.length < 1 ? <p>No comments available</p> :
                                                <>
                                                    {comments.map((comment:any, i:number) => (
                                                        <div key={i} className="comment-wrapper py-3 px-4 mb-2">
                                                            <p>{ comment.comment }</p>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="m-0">{ dayjs(comment.created_on).format('MMM D, YYYY h:mm A') }</p>
                                                                <p className="m-0">{ comment.user_name } - { comment.user_type }</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </>
                                        
                                    }
                                </div>
                                <div className="comment-input col-12 col-md-6 offset-md-1">
                                    <textarea name="comment" className={`form-input ${Boolean(touched.comment && errors.comment) ? "error-field": ""}`} 
                                        style={{ width: '100%' }} rows={4} onBlur={handleBlur} onChange={handleChange} value={values.comment} />
                                    <button className="my-3 btn btn-primary btn-full" type="submit">Send</button>
                                </div>
                            </form>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    return (
        <div className="container">
            <Header title="Portfolio amount Outstanding" />
            <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />

            <div className="my-2 d-flex justify-content-start align-items-stretch flex-wrap">
                <div className={"card dues-card green mb-2 d-flex flesx-column justify-content-between align-items-start" + (dues === "within_tenure" ? " dues-selected": null)} onClick={() => handleCardFilterClick('within_tenure')}>
                    <p className="card-title">Amount Within Tenure</p>
                    <p className="card-value">{ (porfolioAges?.within_tenure) ? useNumberFormat(porfolioAges?.within_tenure) : "-" }</p>
                </div>
                <div className={"card dues-card mb-2  d-flex flesx-column justify-content-between align-items-start" + (dues === "due_today" ? " dues-selected": null)}  onClick={() => handleCardFilterClick('due_today')}>
                    <p className="card-title">Due Today</p>
                    <p className="card-value">{ (porfolioAges?.due_today) ? useNumberFormat(porfolioAges?.due_today) : "-" }</p>
                </div>
            </div>
            <p className="mt-2">Past Due</p>
            <div className="mb-2 d-flex justify-content-start align-items-stretch flex-wrap">
                <div className={"card dues-card blue mb-2 d-flex flex-column justify-content-between align-items-start" + (dues === "one_to_three" ? " dues-selected": null)}  onClick={() => handleCardFilterClick('one_to_three')}>
                    <p className="card-title">1-3 Days</p>
                    <p className="card-value">{ (porfolioAges?.one_to_three) ? useNumberFormat(porfolioAges?.one_to_three) : "-" }</p>
                </div>
                <div className={"card dues-card yellow mb-2  d-flex flex-column justify-content-between align-items-start" + (dues === "four_to_seven" ? " dues-selected": null)}  onClick={() => handleCardFilterClick('four_to_seven')}>
                    <p className="card-title">4-7 Days</p>
                    <p className="card-value">{ (porfolioAges?.four_to_seven) ? useNumberFormat(porfolioAges?.four_to_seven) : "-" }</p>
                </div>
                <div className={"card dues-card orange mb-2 d-flex flex-column justify-content-between align-items-start" + (dues === "eight_to_fourteen" ? " dues-selected": null)}  onClick={() => handleCardFilterClick('eight_to_fourteen')}>
                    <p className="card-title">8-14 Days</p>
                    <p className="card-value">{ (porfolioAges?.eight_to_fourteen) ? useNumberFormat(porfolioAges?.eight_to_fourteen) : "-" }</p>
                </div>
                <div className={"card dues-card red mb-2  d-flex flex-column justify-content-between align-items-start" + (dues === "more_than_fifteen_days" ? " dues-selected": null)}  onClick={() => handleCardFilterClick('more_than_fifteen_days')}>
                    <p className="card-title">15+ Days</p>
                    <p className="card-value">{ (porfolioAges?.more_than_fifteen_days) ? useNumberFormat(porfolioAges?.more_than_fifteen_days) : "-" }</p>
                </div>
            </div>
            {
                (isLoading) ? 
                <Loader /> :
                <>
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Normal Products" {...a11yProps(0)} />
                                <Tab label="Jaza Plus" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                            {
                                (recordCount === 0) ? 
                                <EmptyPage /> :
                                <section className="s">
                                    <div className="d-flex justify-content-bewteen">
                                        <h4 className="section-header">Jaza, Jaza Pro, Jaza Max, Solvit</h4>
                                    </div>
                                    <div className="col-12 mt-5" style={{ width: "100%", maxWidth: "100%" }} >
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Company Name</TableCell>
                                                    <TableCell>Merchant Name</TableCell>
                                                    <TableCell>Phone</TableCell>
                                                    <TableCell>Loan Type</TableCell>
                                                    <TableCell>Date Approved</TableCell>
                                                    <TableCell>Date Due</TableCell>
                                                    <TableCell>Amount Applied</TableCell>
                                                    <TableCell>Amount Disbursed</TableCell>
                                                    <TableCell>Amount Paid</TableCell>
                                                    <TableCell>Balance</TableCell>
                                                    <TableCell>Days</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {applications.map((row, i:number) => (
                                                <Row key={row.id} row={row} />
                                            ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[ 10, 25, 100]}
                                                        count={recordCount}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                    </div>
                                </section>
                            }
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                            {
                                (minRepayments.length === 0) ? 
                                <EmptyPage /> :
                                <section className="">
                                    <div className="d-flex justify-content-bewteen">
                                        <h4 className="section-header">Jaza Plus & Distributor Advance</h4>
                                    </div>
                                    <div className="col-12 mt-5" style={{ width: "100%", maxWidth: "100%" }} >
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Company Name</TableCell>
                                                    <TableCell>Merchant Name</TableCell>
                                                    <TableCell>Phone</TableCell>
                                                    <TableCell>Loan Type</TableCell>
                                                    <TableCell>Date Approved</TableCell>
                                                    <TableCell>Last Repayment Due</TableCell>
                                                    <TableCell>Amount Payable</TableCell>
                                                    <TableCell>Min Amount Required</TableCell>
                                                    <TableCell>Amount Paid</TableCell>
                                                    <TableCell>Balance</TableCell>
                                                    <TableCell>Expected Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {minRepayments.map((row, i:number) => (
                                                // <Row key={row.id} row={row} />
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">{row.companyName}</TableCell>
                                                    <TableCell>{row.merchantName}</TableCell>
                                                    <TableCell>{row.phoneNumber}</TableCell>
                                                    <TableCell>{row.loanName}</TableCell>
                                                    <TableCell>{dayjs(row.dateApproved).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell>{dayjs(row.dateApproved).add(((row.current_period-1) * 30), 'day').format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell>{useNumberFormat(row.total_amount) }</TableCell>
                                                    <TableCell>{useNumberFormat(row.minRepayment) }</TableCell>
                                                    <TableCell>{useNumberFormat(row.amountPaid) }</TableCell>
                                                    <TableCell>{useNumberFormat(row.balance) }</TableCell>
                                                    <TableCell>{useNumberFormat(row.cumulative_amount) }</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                            <TableFooter>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                    </div>
                                </section>
                            }
                            </TabPanel>
                        </Box>
                    </>
                    <>
                    </>
                </>
            }
        </div>
    )
}


export default PortfolioPage;