import emptyState from 'assets/images/empty-page.svg';

const EmptyState = () => {
    return (
        <div className="mt-5">
            <div className="margin-auto text-center">
                <img className='my-4' src={emptyState} style={{ height: '15vh' }} alt="No results page illustration" />
                <h2 className=''>No results found</h2>
                <p className=''>Try again later or change search filters</p>
            </div>
        </div>
    )
}

export default EmptyState;