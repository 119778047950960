import axios from "axios"

interface AxiosConfig {
    url: string,
    method: string,
    params?: any,
    data?: any,
    headers?: any
}

export const AxiosWrapper = (config: AxiosConfig) => {
    return axios({
        baseURL: process.env.REACT_APP_BASE_URL,
        url: config.url,
        method: config.method,
        params: config.params,
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        data: config.data
    });
}