import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const AuthLayout = () => {
  return (
    <div className="container-fluid" >
      <div className="auth-wrapper row align-items-center">
        <div className="full-height col-12 col-md-4 row justify-content-center align-items-center px-5 py-3 m-0">
          <div className="auth-layout-banner mx-auto" >
            <img src={logo} className="auth-logo" alt="Adklout Logo" />
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-6 p-5">
          <Outlet />
        </div>
      </div>
    </div>
  )
};

export default AuthLayout;
