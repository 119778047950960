import { Link } from "react-router-dom";
import pageNotFound from "../assets/images/page-not-found.svg";

const PageNotFound = () => {
  return (
    <div className="container">
      <div className="page-not-found d-flex flex-column justify-content-center align-items-center">
        <img className="" src={pageNotFound} alt="" />
        <h2 className="">Oops!.. Page not found</h2>
        <p className="">We suggest you go back to the previous page or login again while we fix the problem</p>
        <div className="">
          <Link to="/sign-in" className="btn btn-primary">Go to Login Page</Link>
        </div>
      </div>
    </div>
  )
};

export default PageNotFound;
