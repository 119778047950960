import { AxiosWrapper } from "utils/axios.helper";
import { HandleError } from "./errorHandler.service";

const CreateComment = (data: {comment: string, recordId: number}) => {
    return AxiosWrapper({
        url: "/comments/create",
        method: "post",
        data: data
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetComments = (params: {id: string}) => {
    return AxiosWrapper({
        url: "/comments",
        method: "get",
        params
    }).catch((error) => {
        return HandleError(error);
    });
}

export {
    CreateComment,
    GetComments
};
