/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { SearchFilters, Header, Loader, EmptyPage } from "components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNumberFormat } from "hooks";
import { VisitsService } from "services";
import { FilterProcessor } from "utils/filter-processor";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import dayjs from "dayjs";

const initialValues = {
  search: "",
  start_date: "",
  end_date: "",
  product: "",
};

const filterOptions = {
  search: {
    canShow: true,
    label: "",
  },
  select: {
    canShow: false,
    label: "Status",
    options: [
      { id: 0, name: "Jaza" },
      { id: 1, name: "Matched" },
      { id: 2, name: "UnMatched" },
      { id: 3, name: "Reversed" },
      { id: 4, name: "Zanifu Acc Top Up" },
    ],
  },
  dates: {
    canShow: false,
  },
};

const VistsPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [recordCount, setRecordCount] = useState(100);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState<any>(initialValues);

  const [vists, setVists] = useState<any[]>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setIsLoading(true);
    const params = FilterProcessor(filters);
    params["filter[select_filters]"] = params["filter[product]"];
    delete params["filter[product]"];
    params.page = page + 1;
    params.per_page = rowsPerPage;

    VisitsService.GetVists(params).then((res: any) => {
      setVists(res.data.data);
      setRecordCount(res.data.meta.total);
      setIsLoading(false);
    });
  }, [filters, page, rowsPerPage]);

  const handleChange = (changes: any) => {
    const key = Object.keys(changes)[0];
    const value = Object.values(changes)[0];

    setPage(0);
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleImage = (url?: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleLoadMap = (latitude?: string, longitude?: string) => {
    const newWindow = window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="">
      <Header title="Merchant Vists" />
      <SearchFilters
        filterOptions={filterOptions}
        onHandleSearchInput={handleChange}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {recordCount === 0 ? (
            <EmptyPage />
          ) : (
            <section className="mt-4">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Street</TableCell>
                      <TableCell>County</TableCell>
                      <TableCell>Region</TableCell>
                      <TableCell>Account Manager</TableCell>
                      <TableCell>Location Image</TableCell>
                      <TableCell>Map Location</TableCell>
                      <TableCell>Date Tagged</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vists.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.company_name}
                        </TableCell>
                        <TableCell>{row.street}</TableCell>
                        <TableCell>{row.county}</TableCell>
                        <TableCell>{row.region}</TableCell>
                        <TableCell>{row.whitelist_name}</TableCell>
                        <TableCell>
                          <button
                            className="btn btn-primary btn-full mt-3"
                            type="button"
                            onClick={() => { handleImage(row?.image_url) }}
                          >
                            View
                          </button>
                        </TableCell>
                        <TableCell>
                          <button
                            className="btn btn-primary btn-full mt-3"
                            type="button"
                            onClick={() => { handleLoadMap(row?.latitude, row?.longitude) }}
                          >
                            View
                          </button>
                        </TableCell>
                        <TableCell>{dayjs(row.created_at).format('MMM D, YYYY h:mm A')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default VistsPage;
