/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import { SearchFilters,  Header, Loader, EmptyPage } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNumberFormat } from "hooks";
import { ApplicationService } from "services";
import { FilterProcessor } from "utils/filter-processor";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { Application } from "interfaces/application.interface";

const initialValues = {
    search: "",
    start_date: "",
    end_date: "",
    product: ""
};

const filterOptions = {
    search: {
        canShow: true,
        label: ""
    },
    select: {
        canShow: true,
        label: "Status",
        options: [
            { id: 0, name: "Jaza" },
            { id: 1, name: "Matched" },
            { id: 2, name: "UnMatched" },
            { id: 3, name: "Reversed" },
            { id: 4, name: "Zanifu Acc Top Up" }
        ]
    },
    dates: {
        canShow: true,
    },
};

const OrdersPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [recordCount, setRecordCount] = useState(100);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState<any>(initialValues);

    const [orders, setOrders] = useState<Application[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setIsLoading(true);
        const params = FilterProcessor(filters);
        params['filter[select_filters]'] = params['filter[product]'];
        delete params['filter[product]'];
        params.page = page+1;
        params.per_page = rowsPerPage;

        ApplicationService.GetOrders(params)
            .then((res: any) => {
                setOrders(res.data.data);
                setRecordCount(res.data.meta.total);
                setIsLoading(false);
            });
    }, [filters, page, rowsPerPage]);

    const handleChange = (changes: any) => {
        const key = Object.keys(changes)[0];
        const value = Object.values(changes)[0];

        setPage(0);
        setFilters({
            ...filters,
            [key]: value
        });
    }

    return (
        <div className="">
            <Header title="Order Sales" />
            <SearchFilters filterOptions={filterOptions} onHandleSearchInput={handleChange} />

            {
                (isLoading) ? 
                <Loader /> :
                <>
                    {
                        (recordCount === 0) ? 
                        <EmptyPage /> :
                        <section className="mt-4">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Merchant Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Amount Borrowed</TableCell>
                                            <TableCell>Amount Payable</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Till Numbers</TableCell>
                                            <TableCell>Agent Assigned</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {orders.map((row, i) => (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{row.companyName}</TableCell>
                                            <TableCell>{row.merchantName}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell>{useNumberFormat(row.amountApplied)}</TableCell>
                                            <TableCell>{useNumberFormat(row.amountPayable)}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.tillNumber} - {row.tillName}</TableCell>
                                            <TableCell>{ row.agentPhone } - { row.agentAssigned }</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[ 10, 25, 100]}
                                                count={recordCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </section>
                    }
                </>

            }
        </div>
    )
}


export default OrdersPage;