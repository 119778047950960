/* eslint-disable import/no-anonymous-default-export */
import { AxiosWrapper } from "utils/axios.helper";
import { HandleError } from "./errorHandler.service";

const GetCustomers = (filters?: any) => {
    return AxiosWrapper({
        url: "/customers",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetCustomerProducts = (filters?: any) => {
    return AxiosWrapper({
        url: "/customers/products",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetInactiveCustomers = (filters?: any) => {
    return AxiosWrapper({
        url: "/customers/inactive",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetDormantCustomers = (filters?: any) => {
    return AxiosWrapper({
        url: "/customers/dormant",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

const GetChurnRiskCustomers = (filters?: any) => {
    return AxiosWrapper({
        url: "/customers/churn-risk",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

export {
    GetCustomers,
    GetCustomerProducts,
    GetInactiveCustomers,
    GetDormantCustomers,
    GetChurnRiskCustomers
};