
const HandleError =( error: any) => {
    const errorStatus = error.response.status;

    // Force page redirect
    if (errorStatus === 401) {
        sessionStorage.clear();

        window.history.pushState({}, "Sign-In", "/sign-in?message=session");
        window.location.reload();
    }

    return error;
}

export {
    HandleError
};