import React from "react";
import { Navigate } from "react-router-dom";
import { PageNotFound, B2BPage, BlacklistPage, C2BPage, CustomersPage, Dashboard, OrdersPage, PortfolioPage, SignInPage, OtpPage, SalesPage, VistsPage, InactiveCustomersPage, DormantCustomersPage, ChurnRiskCustomersPage } from "views";
import { AuthLayout, AdminLayout } from "views/layouts";

const routes: any = [
    { path:"", element: <Navigate to="sign-in" /> },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "sign-in", element: <SignInPage /> },
        { path: "otp", element: <OtpPage /> },
        { path: "register" },
        { path: "reset-password" },
        { path: "forgot-password" },
      ],
    },
    {
      path: "/",
      element: <AdminLayout />,
      children: [
        { path: "b2b", element: <B2BPage />  },
        { path: "blacklist", element: <BlacklistPage />  },
        { path: "c2b", element: <C2BPage />  },
        { path: "dashboard", element: <Dashboard /> },
        { path: "customers", element: <CustomersPage />  },
        { path: "customers/inactive", element: <InactiveCustomersPage />  },
        { path: "customers/dormant", element: <DormantCustomersPage />  },
        { path: "customers/churn-risk", element: <ChurnRiskCustomersPage />  },
        { path: "orders", element: <OrdersPage />  },
        { path: "portfolio", element: <PortfolioPage />  },
        { path: "sales", element: <SalesPage />  },
        { path: "vists", element: <VistsPage />  },
      ],
    },
    { path: "*", element: <PageNotFound /> },
  ];
  
  export default routes;
  