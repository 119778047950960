/* eslint-disable import/no-anonymous-default-export */
import { AxiosWrapper } from "utils/axios.helper";
import { HandleError } from "./errorHandler.service";

const GetDisbursments = (filters?: any) => {
    return AxiosWrapper({
        url: "/disbursments",
        method: "get",
        params: filters
    }).catch((error) => {
        return HandleError(error);
    });
}

export {
    GetDisbursments,
};