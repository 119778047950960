import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthorizationService } from "services";
import { Loader } from "components";
import { useFormik } from 'formik';
import { object, string } from 'yup';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from "hooks";

interface OTPValues {
    otp: string;
}

const OTPPage = () => {
    const [id, setId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const initialValues: OTPValues = { otp: '' };

    const validationSchema = object().shape({
        otp: string()
            .required('OTP is required'),
    });

    useEffectOnce(() => {
        const v = searchParams.get('val')!;
        setId(v);
    })
    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues, validationSchema,
        onSubmit: async () => {
            setIsLoading(true);
            setErrorMessage("");
            
            AuthorizationService.VerifyOTP(values.otp, id)
            .then((res) => {
                sessionStorage.setItem("token", res.data.token);
                sessionStorage.setItem("user-profile", JSON.stringify(res.data.user));
                sessionStorage.setItem('logged-in-time', dayjs().format('DD/MM/YYYY'));

                setIsLoading(false);
                navigate('/dashboard');
            }).catch((err) => {
                setIsLoading(false);
                console.log(err)
                if (err.response.status === 401) {
                    setErrorMessage("Invalid email address or password");
                    return;
                } 
                setErrorMessage("Something wrong happened. Please try again later");
            })
        }
    });

    return (
        <form className="col-12 col-md-8 col-xl-6 mx-auto" onSubmit={handleSubmit}>
            <h3 className="mb-3">Sign In to Zanifu TMs Platform to continue</h3>
            {
                errorMessage &&
                <p className="error-message">{ errorMessage }</p>
            }
            { 
                    (isLoading) ? 
                    <Loader /> :
                    <>
                        <div className="form-group mb-3">
                            <label>Enter OTP</label>
                            <input name="otp" className={`form-input ${Boolean(touched.otp && errors.otp) ? "error-field": ""}`} 
                                onBlur={handleBlur} onChange={handleChange} value={values.otp} type="text" placeholder="Enter OTP code" required />
                            {Boolean(touched.otp && errors.otp) &&
                                <p className='error mt-2'> { errors.otp }</p>
                            }
                        </div>

                        <button className="btn btn-primary btn-full mt-3" type='submit'>
                            Submit OTP
                        </button>
                    </>
            }
        </form>
    )
};

export default OTPPage;